import React, { useState, useCallback } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Button from '../components/Button';
import styles from './contact.module.css';

function ContactPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const onNameChange = useCallback(event => {
    setName(event.target.value);
  });

  const onEmailChange = useCallback(event => {
    setEmail(event.target.value);
  });

  const onSubmit = useCallback(event => {
    const nextFormErrors = {};

    if (!name) {
      nextFormErrors.name = 'A name is required';
    }

    if (!email) {
      nextFormErrors.email = 'An email address is required';
    }

    // Do not allow form submission if there are any errors.
    if (Object.keys(nextFormErrors).length) {
      event.preventDefault();
    }

    setFormErrors(nextFormErrors);
  });

  return (
    <Layout>
      <SEO title="Contact Me" />
      <h1>Contact Me</h1>
      <form
        action="https://formspree.io/hannah@bebravediversity.com"
        method="POST"
        onSubmit={onSubmit}
      >
        <div className={styles.inputContainer}>
          <input
            className={styles.text}
            type="text"
            name="name"
            placeholder="Your name"
            value={name}
            onChange={onNameChange}
          />
          <span className={styles.error}>{formErrors.name}</span>
        </div>

        <div className={styles.inputContainer}>
          <input
            className={styles.text}
            type="email"
            name="email"
            placeholder="Your email"
            value={email}
            onChange={onEmailChange}
          />
          <span className={styles.error}>{formErrors.email}</span>
        </div>

        <div className={styles.inputContainer}>
          <input
            className={styles.text}
            type="text"
            name="organization"
            placeholder="Your organization"
          />
        </div>

        <div className={styles.inputContainer}>
          <textarea
            className={styles.message}
            name="message"
            placeholder="What can we help you with?"
          ></textarea>
        </div>

        <div className={styles.inputContainer}>
          <Button className={styles.sendButton} type="submit">
            Send
          </Button>
        </div>
      </form>
    </Layout>
  );
}

export default ContactPage;
